// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";
@import "image";

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid #cfcfcf;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: var(--cui-input-focus-color, rgba(44, 56, 74, 0.95));
  background-color: "!important";
  border-color: #cfcfcf;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
}

li.nav-group
  > ul.nav-group-items
  > li.nav-group
  > ul.nav-group-items
  > li.nav-item
  a.nav-link {
  padding-left: 5em !important;
  white-space: pre-line !important;
}

li.nav-group > ul.nav-group-items > li.nav-item a.nav-link {
  white-space: pre-line !important;
}

.bg-abu {
  color: grey;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: unset !important;
}
.postion-center {
  text-align: center;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: unset !important;
}

.imagePreviewWrapper {
  width: 28.125rem;
  height: 15.625rem;
  display: block;
  // cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.imageDonationPreviewWrapper {
  // max-height: 280px;
  // width: 28.125rem;
  // height: 15.625rem;
  display: block;
  // cursor: pointer;
  margin: 0 auto 30px;
  padding: 0.5rem;
  background-size: cover;
  background-position: center center;
}
// config checkout
.checkout {
  color: red !important;
}
.checkout-container {
  display: flex;
  align-items: center;
}
.border-co {
  border: 1px solid #8f8f8f;
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;
}
//notification
.notification {
  border: 2px solid #8f8f8f;
  height: 170px;
  border-radius: 8px;
  padding: 1rem;
}
.notification-img {
  display: flex;
  align-items: center;
}
.notification-img img {
  width: 100%;
  height: 8rem;
}
.imgnotif {
  border: 1px solid #8f8f8f;
  border-radius: 8px;
}
.notification-img2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.text-decoration {
  text-decoration: none;
  color: black;
}
.text-decoration:hover {
  color: blue;
}
.add-more {
  background-color: white;
}

.sidebar-nav .nav-link {
  // display: flex;
  // flex: 1 1;
  // align-items: center;
  // padding: var(--cui-sidebar-nav-link-padding-y)
  //   var(--cui-sidebar-nav-link-padding-x);
  color: #a3aed0 !important;
  // text-decoration: none;
  white-space: unset !important;
  // background: var(--cui-sidebar-nav-link-bg);
  // border: var(--cui-sidebar-nav-link-border);
  // border-radius: var(--cui-sidebar-nav-link-border-radius);
  // transition: "background 0.15s ease, color 0.15s ease";
}

.swiper-slide-info-web {
  width: 23rem !important;
  height: 23rem !important;
}

.sidebar {
  --cui-sidebar-bg: #fff !important;
  --cui-sidebar-nav-group-indicator: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%28163, 174, 208, 1%29%27><path fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/></svg>") !important;
}

.sidebar-nav .nav-link.active {
  color: #5d5fef !important;
  background: #5d5fef45 !important;
  border-left: 3px solid #5d5fef !important;
  svg {
    path {
      fill: #5d5fef;
    }
  }
}

.sidebar-brand {
  background: #fff;
}

.sidebar-nav .nav-link:hover {
  color: #5d5fef !important;
  text-decoration: none;
  background: #5d5fef45 !important;
  border-left: 3px solid #5d5fef !important;
  svg {
    path {
      fill: #5d5fef;
    }
  }
}

.sidebar-nav .nav-group.show {
  background: rgb(255 255 255);
}

.sidebar-nav .nav-group.show .nav-group-toggle.active {
  color: #5d5fef !important;
  text-decoration: none;
  background: #5d5fef45 !important;
  border-left: 3px solid #5d5fef !important;
  svg {
    path {
      fill: #5d5fef;
    }
  }
}

.sidebar-nav .nav-title {
  // padding: var(--cui-sidebar-nav-title-padding-y) var(--cui-sidebar-nav-title-padding-x);
  // margin-top: var(--cui-sidebar-nav-title-margin-top);
  font-size: 90%;
  font-weight: bold;
  color: #a3aed0 !important;
}

.sidebar-nav .nav-link.active .nav-icon {
  color: #5d5fef !important;
}

.sidebar-nav::-webkit-scrollbar {
  width: 0.3em;
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background-color: rgb(201, 201, 201);
  border-radius: 12px;
}

.new-card {
  box-shadow: 0px 3.5px 5.499999523162842px 0px #00000005;
  background: #ffffff;
  border-radius: 15px;
  border-color: #00001500;
}

// .card {
//   --cui-card-border-color: unset !important;
//   --cui-card-border-radius: 15px !important;
//   --cui-card-box-shadow: 0px 3.5px 5.499999523162842px 0px #00000005 !important;
// }

.sidebar-nav .nav-link:hover.nav-group-toggle::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%2893, 95, 239, 1%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.bg-light {
  background-color: #f5f5f6 !important;
}

.header {
  --cui-header-bg: #fff0 !important;
  --cui-header-border-color: #d8dbe000 !important;
}

.header.header-sticky {
  backdrop-filter: blur(6px) !important;
  background-color: #f5f5f6bd !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #5d5fef !important;
  background: #5d5fef45 !important;
  svg {
    path {
      fill: #5d5fef;
    }
  }
  div {
    color: #5d5fef;
  }
}

.line {
  position: relative;
}

.line:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: -10px;
  left: -4px;
  transform: scaleX(0);
  background: #5d5fef;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

.line:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.color-text-universal {
  margin-top: 1rem;
  color: #a3aed0;
}

.sidebar-header {
  background: #fff !important;
}
