@import "orders";
@import "category";

// Here you can add other styles
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border: 1px solid #cfcfcf !important;
  // border-color: rgba($color: #000000, $alpha: 0) !important;
  box-shadow: unset !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  z-index: 0 !important;
  border-color: #e55353;
  background-image: unset !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border: 1px solid #cfcfcf !important;
  // border-color: rgba($color: #000000, $alpha: 0) !important;
  background-image: unset !important;
}

.form-select:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}

.modal-footer {
  border-top: unset !important;
}

.modal-header {
  border-bottom: unset !important;
}

:root {
  --cui-body-font-size: 0.875rem !important;
}
.MuiToolbar-root {
  z-index: 0 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: rgb(255 255 255 / 87%) !important;
  background-color: var(--cui-nav-pills-link-active-bg) !important;
}
.nav-pills .nav-link {
  background: white !important;
  border: 0 !important;
  border-radius: var(--cui-nav-pills-border-radius) !important;
}

.remove-colorBg-accordion {
  --cui-accordion-active-color: unset !important;
  --cui-accordion-active-bg: unset !important;
  --cui-accordion-btn-focus-box-shadow: unset !important;
  --cui-accordion-btn-focus-border-color: unset !important;
  --cui-accordion-btn-color: unset !important;
  --cui-accordion-border-color: unset !important;
  --cui-accordion-body-padding-x: 0.25rem;
  --cui-accordion-btn-padding-x: 0.25rem;
  // --cui-accordion-border-width: ;
  // --cui-accordion-border-radius: 0.375rem;
}

.custom-accordion-borderColor {
  // --cui-accordion-btn-focus-border-color: unset !important;
  --cui-accordion-btn-focus-box-shadow: unset !important;
  // --cui-accordion-border-color: unset !important;
  // --cui-accordion-btn-focus-border-color: unset !important;
}

/* Works on Firefox */
.donation-list-image,
.product-order-list {
  scrollbar-width: thin;
  // scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
// *::-webkit-scrollbar {
//   width: 20px;
// }

// *::-webkit-scrollbar-track {
//   background: #ffffff99;
// }

// *::-webkit-scrollbar-thumb {
//   background-color: rgba(255, 255, 255, 0.87);
//   border-radius: 20px;
//   border: 3px solid #ffffff99;
// }
.donation-list-image {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0 0 1rem 0;
  // max-height: 200px;
  overflow: auto;
}
.donation-list-image::-webkit-scrollbar,
.product-order-list::-webkit-scrollbar {
  width: 0.5rem; /* width of the entire scrollbar */
}

.donation-list-image::-webkit-scrollbar-track,
.product-order-list::-webkit-scrollbar-track {
  // background: #b1b1b199; /* color of the tracking area */
  border-radius: 1rem;
  // border: 3px solid #7a7a7a99; /* creates padding around scroll thumb */
  box-shadow: 0 0 0 1px #7a7a7a99;
}

.donation-list-image::-webkit-scrollbar-thumb,
.product-order-list::-webkit-scrollbar-thumb {
  background-color: #b1b1b199; // rgba(255, 255, 255, 0.87); /* color of the scroll thumb */
  border: 1px solid white; /* creates padding around scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  // background-color: white; // rgba(255, 255, 255, 0.87); /* color of the scroll thumb */
  // border: 3px solid #b1b1b199; /* creates padding around scroll thumb */
}

.detail-disbursement {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  span {
    width: fit-content;
  }
  .date {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #252525;
    user-select: unset;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #3a3a3a;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #3a3a3a;
  }
  .bank-info,
  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  .bank-info {
    color: #6a6a6a;
  }
  .desc {
    color: #3a3a3a;
  }
}

.pointer {
  * {
    cursor: pointer;
    user-select: none;
  }
}
.dot {
  height: 18px;
  width: 18px;
  background-color: #2bc569;
  border-radius: 50%;
  display: block;
}

.custom-ol {
  list-style: none;
  counter-reset: item;
}

.custom-li {
  counter-increment: item;
  margin-bottom: 5px;
}

.custom-li::before {
  margin-right: 10px;
  content: counter(item);
  background: #2bc569;
  border-radius: 100%;
  color: white;
  width: 1.2em;
  text-align: center;
  display: inline-block;
  margin-left: -1.68rem;
}

._loading_overlay_overlay {
  z-index: 9999 !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.auto-payment {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  font-weight: bold;
  color: #ff9723;
  background: #ff97231a;
  border-radius: 0.5rem;
}

.sebagian {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  font-weight: bold;
  color: #246bfd;
  background: #246bfd1a;
  border-radius: 0.5rem;
}

.terbayar {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  font-weight: bold;
  background: #1a98821a;
  color: #1a9882;
  border-radius: 0.5rem;
}

.takTerbayar {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  font-weight: bold;
  color: #eb3d4d;
  background: #eb3d4d1a;
  border-radius: 0.5rem;
}

.banner-button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  button {
    margin-bottom: 0.2rem;
  }
}

.select__menu {
  z-index: 3 !important;
}

.custom-nav-menu {
  transition: all 0.5s ease 0s;
}

.custom-nav-menu :hover {
  filter: drop-shadow(2px 3px 4px rgb(224, 224, 224));
}

.back-button :hover {
  text-decoration: underline;
}

.elipsis-text-id {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  white-space: nowrap;
  width: 2.8rem;
}

.elipsis-text-id:focus,
.elipsis-text-id:hover {
  // color: transparent;
  width: 190px;
  cursor: copy;
}
.elipsis-text-id:focus:after,
.elipsis-text-id:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  // background: #fff;
  position: absolute;
  // left: auto;
  // top: auto;
  // width: auto;
  // max-width: 20 rem;
  border: 1px solid #eaebec;
  border-radius: 4px;
  // padding: 0 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  // white-space: normal;
  // word-wrap: break-word;
  display: block;
  // color: black;
  margin-top: -2.5rem;
}
