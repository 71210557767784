.img-product-overlay {
  background: #0000003c;
  width: 105%;
  height: 105%;
  top: -115px;
  position: relative;
  display: none;
  -webkit-transition: 1s all ease-in-out;
  -moz-transition: 1s all ease-in-out;
  -o-transition: 1s all ease-in-out;
  transition: 1s all ease-in-out;
  opacity: 0;
}
.img-product-overlay-button {
  width: 15px;
  height: 15px;
}
.img-product {
  width: 115px;
  height: 115px;
  margin: 2px;
  border-radius: 10px;
  border: 1px dashed lightgray;
  padding: 0px;
  overflow: hidden;
}

.img-product-noBorder {
  width: 115px;
  height: 115px;
  margin: 2px;
  padding: 0px;
  overflow: hidden;
}

.img-product:first-of-type {
  margin-left: 0px;
}

.img-product:last-of-type {
  margin-right: 0px;
}

.img-product:hover input {
  cursor: pointer;
}

/* .img-product:hover .img-product-overlay {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
  } */

.img-product > img {
  object-fit: cover;
  height: 100%;
}
.img-product-noBorder > img {
  object-fit: cover;
  height: 100%;
}

.img-product [type="file"] {
  appearance: initial;
  opacity: 0;

  width: 115px;
  height: 115px;
  position: absolute;
}
.img-product [type="file"] + label {
  font-family: sans-serif;
  background: #f44336;
  padding: 10px 30px;
  border: 2px solid #f44336;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
}
.img-product [type="file"] + label:hover {
  background-color: #fff;
  color: #f44336;
}
