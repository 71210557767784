.category-container {
  border-radius: 0.3rem;
  border: 0.3px solid rgba(170, 170, 170, 0.257);
  padding: 0.5rem;

  .category-list {
    height: 7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    user-select: none;
    padding-left: 1rem;
    padding-right: 1rem;

    .content {
      height: calc(100% - 2rem);
      display: flex;
      align-items: center;
      gap: 2rem;
      // user-select: none;
      img {
        //   width: 100%;
        border-radius: 0.5rem;
        height: 100%;
        background: #8d8d8d62;
      }
    }

    .category-action {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .lv2 {
    margin-left: 2rem;
    border-top: 0.3px solid rgba(170, 170, 170, 0.257);
  }
  .lv3 {
    margin-left: 3rem;
    border-top: 0.3px solid rgba(170, 170, 170, 0.257);
  }
}
